import React from 'react';

import SEO from '@components/common/seo';
import ErrorResults from '@components/common/errorResults';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <ErrorResults errCode={'notfound'} />
  </>
);

export default NotFoundPage;
